html, body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure the body takes up the full width */
}

.uk-button{
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    display: inline-block;
    box-sizing: border-box;
    padding: 0;
    vertical-align: middle;
    font-size: 16px!important;
    text-align: center;
    text-decoration: none;
    height: 45px;
}

.uk-button-primary {
    background-color: #22526c;
    color: #ffffff;
    padding: 0px 0px;
}

.uk-button-secondary {
    background-color: #22526c;
    color: #ffffff;
    border: solid #22526c 2px;
    margin: none!important;
    line-height: 16px!important;
    box-sizing: border-box;
    height: 48px;
}

.send-quote-button{
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
}

.uk-button-secondary:hover {
    background-color: #14303f;
}

.cat-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.cat-buttons .uk-button{
    width: calc(25% - 8px);
    box-sizing: border-box;
}

.uk-button-primary:hover {
    background-color: #14303f;
}

.uk-active {
    background-color: #14303f!important;
}

.uk-input {
    height: 48px;
}

.uk-select {
    height: 48px!important;
}

input {
    width: 100%!important;
    -moz-appearance: textfield; /* Firefox */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
    margin: 0;
}

.uk-form-label {
    font-size: 16px;
}

.uk-button:disabled {
    background-color: lightgrey;
    color: rgb(119, 119, 119);
    cursor: not-allowed; /* Optional: Change cursor style to indicate disabled state */
    border: solid lightgrey 2px;
}

/* Remove hover effect when the button is disabled */
.uk-button:disabled:hover {
    background-color: lightgrey;
}

.uk-section-primary {
    --uk-inverse: light;
    background: #22526c;
}

.subcat-buttons {
    display: flex; /* Enable flexbox */
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.subcat-buttons .uk-button {
    flex: 1; /* Make buttons grow equally */
}

.subcat-buttons .uk-button {
    min-height: 40px; /* Ensure a minimum height for buttons */
}

.sticky-banner{
    width: 100%;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    background-color: #22526c;
    color: #ffffff;
    box-sizing: border-box;
    align-items: center;
    height: 70px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
}

.mobile-title{
    font-size: 20px;
}

.menu-icon{
    width: 50px;
}

.mobile-logo{
    width: 50px;
    padding: 8px;
    box-sizing: border-box;
}

.mobile{
    display: none !important;
}

.desktop{
    display: block !important;
}

.email-input{
    border-top-left-radius: 4px!important;
    border-bottom-left-radius: 4px!important;
    height: 60px;
}

/* Media Query for screens below 510px */
@media (max-width: 1200px) {
    .uk-button {
        font-size: 12px; /* Decrease font size */
        line-height: 40px; /* Adjust line height */
    }

    .uk-input,
    .send-quote-button,
    .uk-select {
        height: 40px!important; /* Reduce input/select height */
        margin-bottom: 20px!important;
    }

    .uk-form-label {
        font-size: 14px; /* Smaller font size for labels */
    }

    .uk-button-primary {
        height: 40px;
        line-height: normal;
    }
}

/* Media Query for screens below 510px */
@media (max-width: 500px) {
    .uk-button {
        font-size: 16px!important;
        padding: 0px 10px;
    }

    .uk-button-secondary{
        border: solid #ffffff 2px;
    }
    .cat-buttons .uk-button{
        width: calc(50% - 5px);
    }

    .desktop{
        display: none !important;
    }

    .mobile{
        display: block !important;
    }

    .uk-margin-large-top{
        margin-top: 10px!important;
    }

    .uk-margin-medium-top{
        margin-top: 16px!important;
    }
    .uk-margin-small-bottom{
        margin-bottom: 16px!important;
    }
    
    .uk-form-label{
        font-size: 18px!important;
        margin-bottom: 4px!important;
    }

    .uk-margin-medium-bottom{
        margin-bottom: 16px!important;
    }

    .ui-container{
        margin-bottom: 0px!important;
    }
}

@media (max-width:330px) {

    .mobile-title{
        font-size: 18px;
    }

    .menu-icon{
        width: 40px;
    }

    .mobile-logo{
        width: 40px;
        padding: 8px;
    }

    .uk-button{
        font-size: 15px!important;
    }
}