/* sliderInput.css */

.slider-container {
    position: relative;
    width: 100%;
}

.slider-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
}

.slider-input-wrapper-wrapper{
    width: 100%;
    padding: 10px;
}

.slider-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: lightgray;
    padding: 4px;
    border-radius: 100px;
}

.slider-labels{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0px;
    user-select: none;
}

.slider {
    width: calc(100% - 4px);
    appearance: none;
    background-color: rgba(211, 211, 211, 0);
    height: 20px;
    outline: none;
    box-sizing: content-box;
    cursor: pointer;
}

.slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #22526c;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
}

.slider-value {
    width: 10%;
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    float: right;
    vertical-align: middle;
}

.slider-value-input{
    width: 150px;
    display: flex;
    align-items: center;
}

.slider-value-input input {
    width: 100%!important;
    -moz-appearance: textfield; /* Firefox */
    padding-left: '10px';
    float: 'right';
}

.slider-value-input input::-webkit-outer-spin-button,
.slider-value-input input::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
    margin: 0;
}

/* Media Query for screens below 510px */
@media (max-width: 500px) {
    .slider-value-input input{
        margin-bottom: 0px!important;
    }
}