.dimension-input-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.input-container{
    width: 33%;
}

@media (max-width: 500px) {
    .dimension-input-container{
        flex-direction: column;
        width: 100%;
        gap: 5px;
    }

    .input-container{
        width: 100%;
    }

    .input-container input{
        margin-bottom: 0px!important;
    }
}