/* Sticky footer styles */
.sticky-footer {
    background-color: #22526c;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0; /* Ensure it starts from the left side */
    right: 0; /* Ensure it stretches to the right side */
    padding: 20px;
    padding-bottom: 40px;
    text-align: right;
    height: 32px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease-in-out; /* Smooth fade effect */
    z-index: 1000; /* Keep it above other elements */
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 6px;
}

.sticky-footer.show {
    opacity: 0.95; /* Show when U-value is available */
}

/* Text alignment */
.sticky-footer div {
    text-align: right;
    height: fit-content;
}
